import {StackedAreaChartBuilder} from "./ComponentSource/StackedAreaChartBuilder";
import {DatePickerBuilder} from "./ComponentSource/DatePickerBuilder";
import {DateRangePickerBuilder} from "./ComponentSource/DateRangePickerBuilder";
import {EsriFullMapBuilder} from "./ComponentSource/EsriFullMapBuilder";
import {EsriMapBuilder} from "./ComponentSource/EsriMapBuilder";
import {PieChartBuilder} from "./ComponentSource/ChartBuilder";
import {IndexedDbWorkerWrapper} from "./ComponentSource/IndexedDbWorkerWrapper";
import {PhoneNumberBuilder} from "./ComponentSource/PhoneNumberBuilder";
import {NumberMaskBuilder} from "./ComponentSource/NumberMaskBuilder";
import {ServiceProviderSelectJs} from "./ComponentSource/ServiceProviderSelectJs";
import {DropdownListBuilder} from "./ComponentSource/DropDownListBuilder";
import {ObservedObjectBuilder} from "./ComponentSource/ObservedObjectBuilder";
import {DeficientReportRenderObject} from "./ComponentSource/DeficientReportRenderObject";
import {PremisesCalendarBuilder} from "./ComponentSource/PremisesCalendarBuilder";


window.SetFocusToElement = function (element) {
    document.getElementById(element).focus();
};

window.MakePieChart = function (id, xName, yName, includeDataLabel, textName, title, pointColorName, includeLegend, legendPosition, legendAlignment) {
    return new PieChartBuilder(id, xName, yName, includeDataLabel, textName, title, pointColorName, includeLegend, legendPosition, legendAlignment);
}

window.MakeFullMap = function (id, dotNetObj) {
    return new EsriFullMapBuilder(id, dotNetObj);
}

window.MakeStackedAreaChart = function (id, xName, yName, xAxisLabel, yAxisLabel, title) {
    return new StackedAreaChartBuilder(id, xName, yName, xAxisLabel, yAxisLabel, title);
}

window.PrintObj = function (obj) {
    console.log(obj);
}

window.PrintJson = function (json_string) {
}

window.CallReportCreate = function (dataModel, dotNetClass) {
    let authToken = window.ReadCookie('authToken');

    axios.get('https://tce-api-dev.thecomplianceengine.com/createreport', {
        headers: {
            'Authorization': `Bearer ${authToken}`
        },
        params: {
            PremisesId: dataModel.premiseId,
            ServiceProviderId: dataModel.serviceProviderId,
            CodeTypeId: dataModel.codeTypeId,
            AppName: 'PWA'
        },
        responseType: 'json'
    }).then(function (response) {

        dotNetClass.invokeMethodAsync('HandleReportCreateInit', response.data)
    })
}

window.CallGetReportById = function (id, dotNetClass) {
    let authToken = window.ReadCookie('authToken');

    axios.get('https://tce-api-dev.thecomplianceengine.com/report/show', {
        headers: {
            'Authorization': `Bearer ${authToken}`
        },
        params: {
            Id: id,
            AppName: 'PWA'
        },
        responseType: 'json'
    }).then(function (response) {
        let mainNode = response.data.RootDisplaySequenceNode

        dotNetClass.invokeMethodAsync('HandleReportGet', response.data.Report, mainNode.Children)
    })
}

window.PostReportCreate = function (postParams, dotNetClass) {
    let authToken = window.ReadCookie('authToken');

    axios.post('https://tce-api-dev.thecomplianceengine.com/createreport', {
        InspectionDateTime: postParams.inspectionDateTime,
        ReportTemplateId: postParams.reportTemplateId,
        InventoryId: postParams.inventoryId,
        TestKitId: postParams.testKitId,
        InventoryTemplateId: postParams.inventoryTemplateId,
        InventoryAttributeValues: postParams.inventoryAttributeValues,
        ServiceProviderId: postParams.serviceProviderId,
        ReportURL: postParams.reportURL,
        PremisesId: postParams.premisesId,
        AttachmentURLs: postParams.attachmentURLs,
        Deficiencies: postParams.deficiencies,
        DisplaySequenceNodes: postParams.displaySequenceNodes,
        ReferenceNumber: postParams.referenceNumber,
        InspectorIds: postParams.inspectorIds,
        AppName: 'PWA'
    }, {
        headers: {
            'Authorization': `Bearer ${authToken}`
        },
        responseType: 'json'
    }).then(function (response) {
        dotNetClass.invokeMethodAsync('HandleReportCreateFinalize', response.data)
    })
}

window.preventClick = function (elt) {
    elt.addEventListener("click", function (e) {
        e.preventDefault()
        e.stopPropagation();
    })
}

window.clickInput = function (id, elt) {
    elt.addEventListener('click', function () {
        let elem = document.getElementById(id);

        const event = new MouseEvent('click', {
            view: window,
            bubbles: false,
        });

        if ((elem !== null) && (elem !== undefined)) {
            elem.dispatchEvent(event);
        }
    });
}

window.MakeSignature = function (id, redoButton) {
    return new SignatureBuilder(id, redoButton);
}

window.getDbWorkerObject = function (serviceName, dotNetObject) {
    return new IndexedDbWorkerWrapper(dotNetObject, serviceName);
}

window.MakeDateRangePicker = function (id, format, start, depth, errorDivId, maxDate, openOnFocus) {
    return new DateRangePickerBuilder(id, format, start, depth, errorDivId, maxDate, openOnFocus);
}

window.MakeDatePicker = function (id, format, start, depth, errorDivId, maxDate, minDate, callObject, dotNetObject, dotNetFunctionName, placeholder) {
    return new DatePickerBuilder(id, format, start, depth, errorDivId, maxDate, minDate, callObject, dotNetObject, dotNetFunctionName, placeholder);
}

window.MakeEsriMap = function (id, dotNetObj, showDetails) {
    return new EsriMapBuilder(id, dotNetObj, showDetails);
}

window.SetRowClass = function (id) {
    let button = document.getElementById(id);
    let buttonDiv = button.parentElement;
    let td = buttonDiv.parentElement;
    let tr = td.parentElement;

    tr.classList.add("e-hide-row");
}
window.CloseToolTip = function (name) {
    const tooltip = document.getElementById(name);
    if (tooltip != null)
        tooltip.classList.add("tooltip-hidden");
}
window.DisplayToolTip = function (name) {
    const tooltip = document.getElementById(name);
    if (tooltip != null)
        tooltip.classList.remove("tooltip-hidden");

}
window.OpenToolTip = function (name) {
    const tooltip = document.getElementById(name);
    if (tooltip != null)
        tooltip.classList.remove("tooltip-hidden");

    document.addEventListener("click", handleClickOut, false);

    function handleClickOut(event) {
        try {
            if (event.target != null) {
                if (event.target.classList != null && !tooltip.contains(event.target) || event.target.classList.contains("e-tooltip-close")) {
                    CloseToolTip(name)
                    document.removeEventListener("click", handleClickOut, false);
                }
            }
        } catch (e) {
        }
    }
}

window.buildPhoneFormatter = function (id, dotNetObjectReference, dotNetFunctionChange, dotNetFunctionInput) {
    return new PhoneNumberBuilder(id, dotNetObjectReference, dotNetFunctionChange, dotNetFunctionInput)
}

window.MaskInput = function (id, val) {
    let selectId = "#" + id;

    console.log(selectId);
    console.log(val);

    $(selectId).mask(val);
}

window.UnmaskInput = function (id) {
    let selectId = "#" + id;
    $(selectId).unmask();
}

window.ScrollToItem = function (id) {
    let item = document.getElementById(id);

    item.scrollIntoView({
        block: "center",
        inline: "center",
        behavior: "smooth"
    });
}

window.AddTooltipToElement = function (id, templateId) {
    return new TooltipBuilder(id, templateId);
}

window.OpenBlank = function (url) {
    window.open(url, "_blank");
}

window.BuildDropDown = function(id, placeholder, data, textName, valueName, dotNetObject, dotNetFunctionName){
    return new DropdownListBuilder(id, placeholder, data, textName, valueName, dotNetObject, dotNetFunctionName);
}

window.BuildObservedObject = function (id, dotNetObject, dotNetFunctionName, keyVal){
    return new ObservedObjectBuilder(id, dotNetObject, dotNetFunctionName, keyVal);
}

window.MakeDeficientReportObject = function(id, reportDetail, isServiceProvider, serviceProviderId){
    return new DeficientReportRenderObject(id, reportDetail, isServiceProvider, serviceProviderId);
}
window.buildCreditFormatter = function (id, placeholder, length, dotNetObjectReference, dotNetFunctionChange, dotNetFunctionInput) {
    return new CreditCardNumberBuilder(id, placeholder, length, dotNetObjectReference, dotNetFunctionChange, dotNetFunctionInput)
}

window.readAndSet = function (id, url, mimeType, dotNetObject, dotNetFunctionName) {
    if (!id && !url) {
        dotNetObject.invokeMethodAsync(dotNetFunctionName, true, false);
        return;
    }

    let elem = document.getElementById(id);

    fetch(url).then(function (response) {
        dotNetObject.invokeMethodAsync(dotNetFunctionName, response.status === 200, response.status === 403);

        return response.text();
    }).then(function (data) {
        elem.src = `data:${mimeType};base64,${data}`;
    });
}

window.downloadFile = function (name, url) {
    if (!url) {
        return;
    }

    const downloadLink = document.createElement("a");
    downloadLink.download = name;
    downloadLink.href = url;
    downloadLink.target = "_blank";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

window.clearInput = function (inputId) {
    setTimeout(function () {
        var input = document.getElementById(inputId);
        if (input) {
            input.value = "";
        }
    }, 30);
}

window.ScrollToTop = function(){
    scrollTo({top: 0, behavior: "smooth"});
}

window.buildFormatter = function(id, placeholder, length, dotNetObjectReference, dotNetFunctionChange, dotNetFunctionInput) {
    return new NumberMaskBuilder(id, placeholder, length, dotNetObjectReference, dotNetFunctionChange, dotNetFunctionInput);
}

window.MakeServiceSelect = function (id, textName, valueName, placeholder, dotNetObject, dataFunctionName, changeEventName, showSpinnerName, hideSpinnerName, showClearButtonName, hideClearButtonName, initialValue){
    return new ServiceProviderSelectJs(id, textName, valueName, placeholder, dotNetObject, dataFunctionName, changeEventName, showSpinnerName, hideSpinnerName, showClearButtonName, hideClearButtonName, initialValue);
}

window.PremisesCalendar = function(id, dotNetObj) {
    console.log("found id: " + id);
    let calendarEl = document.getElementById(id);
    return new PremisesCalendarBuilder(calendarEl, dotNetObj);
}

window.addBorderLoading = function(id, buttonColor) {
    const idElem = document.getElementById(id)
    const borderLoading = 'rotate-border-' + buttonColor;

    if (idElem != null && idElem.classList != null && !idElem.classList.contains(borderLoading)) {
        idElem.classList.add(borderLoading)
    }
}

window.removeBorderLoading = function(id, buttonColor) {
    const idElem = document.getElementById(id)
    const borderLoading = 'rotate-border-' + buttonColor;

    if (idElem != null && idElem.classList != null && idElem.classList.contains(borderLoading)) {
        idElem.classList.remove(borderLoading)
    }
}

window.localStorageHelper = {
    clear: function() {
        //if we ever decide to expand on session storage or local storage we will to consider how we handle de-impersonating and impersonating
        localStorage.clear();
        sessionStorage.clear();
    }
};

window.checkDatePickerValidFormat = function(id) {
    const idElem = document.getElementById(id);
    
    if (idElem === null || idElem === undefined) {
        return true;
    }
    
    const parentClassList = idElem.parentElement.classList;
    if (parentClassList.contains("e-error")) {
        return false;
    } else {
        return true;
    }
}