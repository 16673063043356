import {DateRangePicker} from '@syncfusion/ej2-calendars';

function getDaysInMonth(year, month) {
    let temp = new Date(year, month, 0);
    return temp.getDate();
}

export class DateRangePickerBuilder {
    constructor(id, format, start, depth, errorDivId, maxDate, openOnFocus) {
        this.id = id;
        this.errorDivId = errorDivId;
        this.clearCalled = false;

        this.maxDate = new Date(maxDate);

        let date = new Date(maxDate);

        if (depth === 'Year')
            date = new Date(date.getFullYear(), date.getMonth(), getDaysInMonth(date.getFullYear(), date.getMonth() + 1), 23, 59, 59);

        this.the_picker = new DateRangePicker({
            placeholder: "Select Range",
            openOnFocus: openOnFocus,
            format: format,
            start: start,
            depth: depth,
            max: date
        })

        this.currentStartDate = null;
        this.currentEndDate = null;
        this.parentElem = null;
        this.theElem = null;
        this.addingToClassList = false;
        this.errorsFound = false;

        this.the_picker.addEventListener("cleared", (args) => {
            this.clearCalled = true;
        })

        this.the_picker.addEventListener("change", (args) => {
            if (args.value === null && this.parentElem.classList.contains("syncfusion-input-loaded"))
                this.parentElem.classList.remove("syncfusion-input-loaded");
            
            if (!this.addingToClassList && this.parentElem.classList.contains("syncfusion-input-loaded")) {
                this.parentElem.classList.remove("syncfusion-input-loaded");
            } else {
                this.addingToClassList = false;
            }

            let errors = [];

            if (this.clearCalled) {
                this.clearCalled = false;
                this.addErrorsToDiv(errors)
                return;
            }

            if (args.startDate === null || args.endDate === null) {
                errors.push("Invalid date(s) entered")
            } else {
                if (args.startDate > date) {
                    errors.push(`Start date cannot be larger than ${this.maxDate.toLocaleDateString("en-US")}.`);
                }
                if (args.startDate > args.endDate) {
                    errors.push("Start date cannot be larger than end date.");
                }
                if (args.endDate > date) {
                    errors.push(`End date cannot be larger than ${this.maxDate.toLocaleDateString("en-US")}.`);
                }

                this.currentStartDate = args.startDate;
                this.currentEndDate = args.endDate;
            }

            this.addErrorsToDiv(errors);
        });
    }

    appendThePicker() {
        let elem = document.getElementById(this.id);
        this.the_picker.appendTo(elem);
        this.theElem = elem;
        this.parentElem = elem.parentElement;

        elem.addEventListener("input", (args) => {
            if (this.parentElem.classList.contains("syncfusion-input-loaded"))
                this.parentElem.classList.remove("syncfusion-input-loaded");
        });
    }

    updateStartAndEnd(fromDataModel, startDate, endDate) {
        if (fromDataModel) {
            if (!this.parentElem.classList.contains("syncfusion-input-loaded")) {
                this.parentElem.classList.add("syncfusion-input-loaded");
                this.addingToClassList = true;
            }
        }

        this.the_picker.value = [startDate, endDate];
    }

    readStartAndEnd() {
        return {
            startDate: this.the_picker.startDate,
            endDate: this.the_picker.endDate,
            errorsFound: this.errorsFound
        }
    }

    updateDateRangeClassAfterRead() {
        if (!this.parentElem.classList.contains("syncfusion-input-loaded"))
            this.parentElem.classList.add("syncfusion-input-loaded");
    }

    addErrorsToDiv(errorList) {
        this.errorsFound = errorList.length !== 0;

        let errorDiv = document.getElementById(this.errorDivId);

        while (errorDiv.firstChild) {
            errorDiv.removeChild(errorDiv.firstChild);
        }

        let ul = document.createElement("ul");

        errorList.forEach((error) => {
            let li = document.createElement("li");
            li.classList.add("text-danger");
            li.innerText = error;
            ul.appendChild(li);
        })

        errorDiv.appendChild(ul)
    }

    clearData() {
        if (this.parentElem.classList.contains("syncfusion-input-loaded")) {
            this.parentElem.classList.remove("syncfusion-input-loaded");
        }
        this.the_picker.value = [null, null];
        this.parentElem.value = null;

        this.the_picker.refresh();
    }

    addPresets() {
        this.the_picker.presets = [
            {
                label: 'This Month to Date',
                start: new Date(new Date(new Date(new Date().setMonth(new Date().getMonth())).setDate(1)).toDateString()),
                end: new Date(new Date(new Date().setHours(0, 0, 0, 0)).toDateString())
            },
            {
                label: 'Last 12 Months',
                start: new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 12)).setDate(1)).toDateString()),
                end: new Date(new Date(new Date().setHours(0, 0, 0, 0)).toDateString())
            },
            {
                label: 'Last Month',
                start: new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()),
                end: new Date(new Date(new Date().setDate(0)).toDateString())
            },
            {
                label: 'This Year to Date',
                start: new Date(new Date(new Date().getFullYear(), 0, 1).toDateString()),
                end: new Date(new Date(new Date().setHours(0, 0, 0, 0)).toDateString())
            },
            {
                label: 'Last Year',
                start: new Date(new Date(new Date().getFullYear() - 1, 0, 1).toDateString()),
                end: new Date(new Date(new Date().getFullYear() - 1, 11, 31).toDateString())
            }
        ];
    }
}